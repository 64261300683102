@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500&family=Titillium+Web&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  background-color: #03050e;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  /* text-decoration: underline; */
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
}

.main_navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 20px 0;
}

.buytoken .head_box {
  padding: 20px;
  background: linear-gradient(133deg, #070807 40%, #010102);
}

.buytoken .head_box h5 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3px;
}

.footer_url {
  color: #49c527;
  white-space: normal;
  word-break: break-all;
}

.footer_url:hover {
  color: #49c527;
  text-decoration: underline;
}

h5.head_box_price span {
  color: #49c527;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  /* margin-left: 0; */
}

.buytoken .head_box p span {
  color: #ffffff;
}

a.history_table {
  color: #7f8076;
  text-decoration: underline;
}

a.history_table:hover {
  color: #7f8076;
}

.navbar-brand {
  outline: 0 !important;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0b0e13;
  margin-top: 0px;
  -webkit-box-shadow: 0px 14px 15px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 14px 15px 0px rgba(0, 0, 0, 0.35);
}

.navbar_right {
  display: flex;
  align-items: center;
  width: 100%;
}

.offcanvas .links {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  border-radius: 999px;
  background: transparent;
  padding: 12px 34px;
  margin: 0 auto;
}

.offcanvas .links li {
  margin: 0 20px;
}

.offcanvas .links li a {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.offcanvas .links li a:hover,
.offcanvas .links li a.active {
  color: #fff;
  text-decoration: none !important;
}

/**** tick symbol withdraw******/
svg.success {
  width: 80px;
  display: block;
  margin: 23px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;

  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }

  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }

  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/**** tick symbol withdraw******/
/* Connect Wallet */
.primary_modal .modal-content {
  background: #0B0E13;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #292c2e;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #292c2e;
  background-color: transparent;
  color: #000;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

span.token_pos {
  position: absolute;
  top: 16px;
  right: 30px;
}

.buytoken select {
  border: 1px solid #1a1d21;
  margin-bottom: 15px;
  padding: 10px;
  outline: 0;
  background-image: url("./assets/images/dropdown_arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: 97% center !important;
  appearance: none;
  padding-right: 35px;
}

.buytoken select option {
  background: #03050e !important;
}

.connect_wallet_button:hover {
  border-color: #292c2e;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  filter: invert(1);
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.errortext {
  color: red;
}

.primary_btn {
  border-radius: 60px;
  color: #fff !important;
  text-align: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 15px 60px;
  z-index: 1;
  position: relative;
  transition: 0.3s;
  text-decoration: none !important;
  display: inline-block;
  background: transparent;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

.primary_btn::before,
.primary_btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/images/btn-border.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.primary_btn::after {
  background-image: url("../src/assets/images/btn-border-hover.svg");
  opacity: 0;
}

.primary_btn:hover::after {
  opacity: 1;
}

.main_navbar .primary_btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main_navbar .primary_btn::before {
  background-image: url("../src/assets/images/hd_btn_bg.svg");
}

.main_navbar .primary_btn::after {
  background-image: url("../src/assets/images/hd_btn_bg-hover.svg");
}

.primary_btn_grey::before {
  background-image: url("../src/assets/images/btn-border-white.svg");
}

.primary_btn_grey::after {
  background-image: url("../src/assets/images/btn-border.svg");
}

.blue {
  border: 1px solid #4a7081;
  background: radial-gradient(112.34% 107.5% at 48.33% 53.85%,
      rgba(255, 255, 255, 0.41) 0.56%,
      rgba(0, 0, 0, 0.15) 58.56%,
      rgba(0, 0, 0, 0) 100%);
}

.blue:hover {
  background: radial-gradient(282.34% 107.5% at 48.33% 53.85%,
      rgba(255, 255, 255, 0.41) 0.56%,
      rgba(0, 0, 0, 0.15) 58.56%,
      rgba(0, 0, 0, 0) 100%);
}

.h2tag {
  font-size: 60px;
  font-weight: 400;
  line-height: 70px;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: -1.2px;
  color: #fff;
  display: inline-block;
}

.h2tag span {
  color: #15b383;
  font-weight: 400;
}

.head {
  max-width: 55%;
  margin: 0 auto 20px;
  text-align: center;
}

.h5tag {
  font-size: 22px;
  font-weight: 500;
}

.page_header {
  position: relative;
  padding: 150px 0 100px;
  z-index: 1;
}

.page_header::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banner_bg.png") no-repeat right top;
  top: 0;
  right: 0;
  width: 872px;
  height: 960px;
  background-size: cover;
  z-index: -1;
}

.page_header::after {
  content: "";
  position: absolute;
  background: url("./assets/images/banner_bg_circle.png") no-repeat right top;
  top: 0;
  right: 0;
  width: 1234px;
  height: 100%;
  background-size: cover;
  z-index: -1;
}

.page_header h1 {
  font-size: 60px;
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  color: #fff;
  margin-bottom: 30px;
}

.page_header p {
  font-size: 18px;
  margin-bottom: 50px;
}

.about {
  padding: 60px 0;
  z-index: 1;
  position: relative;
}

.about .aboutbg {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about p {
  font-size: 18px;
  font-weight: 400;
  color: #b3b3c0;
  margin-bottom: 25px;
  line-height: normal;
}

.about ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.about ul li {
  width: 45%;
  color: #b3b3c0;
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
}

.about .shade {
  position: relative;
}

.feature {
  padding: 60px 0;
  position: relative;
}

.feature::before,
.roadmap_new::before {
  content: "";
  position: relative;
  background: url("./assets/images/left_pattern_01.png") no-repeat center;
  width: 209px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  background-size: cover;
  z-index: -1;
}

.feature p,
.feature h5 {
  color: #b3b3c0;
}

.copy_right {
  padding-top: 20px;
  border-top: 1px solid #0C122A;
  margin-top: 15px;
}

.feature h5 {
  margin-bottom: 10px;
  padding-top: 30px;
  margin-bottom: 0;
  display: inline-block;
}

.feature .img_box {
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(182.35% 174.5% at 0% 7%,
      rgba(255, 255, 255, 0.41) 0.56%,
      rgba(0, 0, 0, 0.15) 58.56%,
      rgba(0, 0, 0, 0) 100%);
  margin-bottom: 25px;
}

.feature .img_box::before {
  content: "";
  position: absolute;
  background: #34393f;
  height: 1px;
  width: 90%;
  top: 85px;
  left: 0;
}

.feature .box {
  margin-top: 24px;
  position: relative;
}

.feature .col-lg-4:not(:last-child) .box::after {
  content: "";
  position: absolute;
  right: 8px;
  top: 73px;
  height: 21px;
  width: 2px;
  background: #3d3f3f;
}

.feature .box:hover .img_box::before {
  background: #fff;
  transition: 0.5s;
}

.feature .box:hover h5 {
  background: linear-gradient(90deg, #fff 47.83%, #002a4a 113.76%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  transition: 0.5s;
}

.platform {
  padding: 55px 0;
  position: relative;
  z-index: 1;
}

.platform::before {
  content: "";
  position: relative;
  background: url("./assets/images/left_pattern_02.png") no-repeat center;
  width: 358px;
  height: 812px;
  position: absolute;
  top: 0;
  left: 0px;
  background-size: cover;
  z-index: -1;
}

.platform .box {
  background: #0b0e13;
  padding: 35px;
  padding-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
  transition: 0.8s;
  margin: 15px;
  position: relative;
  -webkit-clip-path: polygon(19% 0, 100% 0, 100% 100%, 0 98%, 0 19%);
  clip-path: polygon(19% 0, 100% 0, 100% 100%, 0 98%, 0 19%);
}

.platform_box_wrapper {
  position: relative;
}

.platform_box_top {
  position: absolute;
  clip-path: polygon(25% 0, 0 0, 0 20%);
  top: 0;
  left: 15px;
  width: 250px;
  height: 250px;
  background: #fff;
  opacity: 0.6;
}

.platform .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: radial-gradient(121.49% 121.49% at 8.33% 50%,
      #14b785 23.33%,
      #2266e7 79.64%);
  filter: blur(21px);
  width: 88px;
  height: 88px;
  top: 50px;
  z-index: -1;
  /* opacity: 0; */
  transition: 0.5s;
  border-radius: 100%;
}

.platform .box .flx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 30px;
  margin-bottom: 30px;
}

.platform .inbox {
  background: linear-gradient(107deg,
      #1f252e 9.66%,
      rgba(31, 37, 46, 0) 85.77%);
  padding: 10px;
}

.platform .inbox h5 {
  font-weight: 400;
  color: #bcbcbc;
  font-size: 14px;
  margin-bottom: 10px;
}

.platform .inbox p {
  font-weight: 500;
  margin-bottom: 0;
  color: #fff;
}

.platform .box a {
  display: flex;
  align-items: center;
  color: #959494;
}

.platform .slick-current .box {
  opacity: 1;
  transform: scale(1);
  transition: 0.8s;
  /* padding-top: 60px;
 padding-bottom: 45px; */
}

.platform .slick-current .platform_box_top {
  opacity: 1;
  background: linear-gradient(161deg, #2367e8 4.7%, #15b383 104.68%);
}

.platform .slick-current .box::before {
  opacity: 1;
  transition: 0.5s;
}

.platform .slick-slider {
  max-width: 100%;
  margin: 80px auto 0;
}

.platform .slick-prev,
.platform .slick-next {
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  top: -110px;
  background: transparent;
}

.slick-next:hover,
.slick-next:focus {
  background: transparent !important;
}

.slick-prev:hover,
.slick-prev:focus {
  background: transparent !important;
}

.platform .slick-prev {
  left: unset;
  right: 50px;
}

.platform .slick-next {
  right: 0;
  left: unset;
}

.platform .slick-prev:before,
.platform .slick-next:before {
  background-color: transparent;
  font-size: 0;
  width: 35px;
  height: 35px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.platform .slick-prev:before {
  background: url("./assets/images/left_arrow.png") no-repeat center;
}

.platform .slick-next:before {
  background: url("./assets/images/right_arrow.png") no-repeat center;
}

.roadmap {
  padding: 125px 0 0;
  padding: 50px 0;
  /* background: url("./assets/images/roadmap_bg1.png") no-repeat center; */
}

.roadmap ul {
  list-style-type: none;
  margin: 0 auto;
  width: fit-content;
  font-size: 18px;
}

.roadmap li {
  position: relative;
  margin-bottom: 10px;
}

.roadmap li::before {
  content: "";
  position: absolute;
  background: url("./assets/images/dot.png") no-repeat center;
  background-size: contain;
  left: -30px;
  top: 5px;
  width: 18px;
  height: 18px;
}

.roadmap .box {
  padding-bottom: 20px;
  border-bottom: 1px solid #34393f;
  border: 0 !important;
  position: relative;
  width: 310px;
}

/* .roadmap .box::before{
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  left: 0;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background: #34393f;
  margin: 0 auto;
} */
.roadmap .box::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: -9px;
  background: radial-gradient(#00a0e3 37%, #00a0e32e 40%) no-repeat;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 1;
}

.roadmap .inbox {
  margin: 0 10px;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00090e;
  background: linear-gradient(180deg, #000203 14.51%, #00090e 126.76%);
  border: 1px solid #181a1d;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  padding-left: 130px;
  border-radius: 20px;
  background: linear-gradient(#000203, #000203) padding-box,
    linear-gradient(110deg, white -150%, transparent 55%, white 136%) border-box;
  border: 1px solid transparent;
}

.roadmap .inbox:hover {
  transition: 0.5s;
  background: linear-gradient(#000203, #000203) padding-box,
    linear-gradient(90deg, white -150%, transparent 55%, white 136%) border-box;
}

.roadmap .inbox::after {
  content: "";
  position: absolute;
  background: url("./assets/images/boxbg.png") no-repeat center;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}

.roadmap .box span {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
  text-transform: capitalize;
}

.roadmap .box p {
  margin: 0px;
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
}

.roadmap .box .title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  text-transform: capitalize;
  background: linear-gradient(90deg, #fff 3.16%, #00a0e3 104.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}

.roadmap .slick-list {
  height: 492px;
  position: relative;
  /* padding: 0 380px; */
}

.roadmap .slick-list::before {
  content: "";
  position: absolute;
  background: #34393f;
  width: 100%;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.roadmap .slick-slide:nth-last-child(1) .box {
  width: unset;
}

.roadmap .slick-slide,
.roadmap .slick-slide>div,
.roadmap .slick-slide:focus-within {
  /* margin: 0 -40px; */
  outline: 0 !important;
  box-shadow: none !important;
}

.roadmap .slick-current {
  /* margin-left: 110px ; */
}

.roadmap .slick-current~.slick-slide {
  /* margin-left: -70px; */
}

.roadmap .slick-slide:nth-child(even) {
  transform: translateY(245px);
}

.roadmap .slick-slide:nth-child(even) .box {
  padding: 0;
  padding-top: 20px;
  border: 0;
  border-top: 1px solid #34393f;
}

.roadmap .slick-slide:nth-child(even) .box::after {
  bottom: unset;
  top: -9px;
}

/* .roadmap .slick-prev,.roadmap .slick-next{
  border-radius: 50%;
  background: transparent !important;
} */
.roadmap .slick-prev:hover,
.roadmap .slick-next:hover {
  background: transparent !important;
}

.roadmap .slick-prev::before,
.roadmap .slick-next::before {
  font-size: 0px;
  color: #36393a;
  width: 35px;
  height: 35px;
  display: block;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.roadmap .slick-prev::before {
  background: url("./assets/images/left_arrow.png") no-repeat center;
}

.roadmap .slick-next::before {
  background: url("./assets/images/right_arrow.png") no-repeat center;
}

.roadmap .slick-prev,
.roadmap .slick-next {
  bottom: -115px;
  height: 46px;
  left: 0;
  margin: 0 auto;
  right: 0;
  top: auto;
  width: 46px;
}

.roadmap .slick-prev {
  left: -65px;
}

.roadmap .slick-prev:focus,
.roadmap .slick-prev:hover {
  background: transparent !important;
}

.roadmap .slick-next {
  background: transparent;
  right: -65px;
}

.roadmap .slick-next:focus,
.roadmap .slick-next:hover {
  background: transparent !important;
}

.faq {
  padding: 0px 0 50px;
  padding: 50px 0;
  margin-top: -200px;
  margin-top: 60px !important;
  position: relative;
  z-index: 10;
}

.faq .accordion {
  max-width: 85%;
  margin: 0 auto;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #0e0f11;
  background: transparent;
  border-radius: 0;
  padding: 30px 0;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

/* .faq .accordion-item:not(:last-child)::before {
  content: "";
  border-radius: 2px;
  background: #3d3f3f;
  height: 3px;
  width: 21px;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  margin: 0 auto;
} */

.faq .accordion-item button {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}

.faq .accordion-item span {
  color: #b3b3c0;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.faq .para {
  color: #B3B3C0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.faq .accordion-body {
  padding: 15px 0;
}

.faq .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/faq_arrow_up.png") no-repeat center;
  transform: none;
  width: 34px;
  height: 34px;
}

.faq .accordion-button.collapsed::after {
  background: url("./assets/images/faq_arrow_down.png") no-repeat center;
  width: 34px;
  height: 34px;
  transition: 0.5s;
}

.token {
  position: relative;
  padding: 45px 0 90px;
}

.token::before,
.faq::before {
  content: "";
  position: relative;
  background: url("./assets/images/right_pattern_01.png") no-repeat center;
  width: 695px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: cover;
  z-index: -1;
}

.roadmap_new {
  padding: 100px 0 50px;
  position: relative;
  z-index: 10;
}

.token .outbox {
  position: relative;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.token .outbox::before {
  content: "";
  position: absolute;
  box-shadow: 0px 102px 100px -99px #00a0e3;
  width: calc(100% - 6px);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 2px;
  left: 0px;
  right: 0;
  margin: auto;
  border-radius: 50%;
}

.token .outbox::after {
  content: "";
  position: absolute;
  background: radial-gradient(109.74% 102.83% at 48.77% -22.37%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 160, 227, 0.04) 42.19%,
      rgba(0, 0, 0, 0) 100%);
  filter: blur(3px);
  width: 100%;
  height: 309px;
  background: url("./assets/images/circle_shade.png") no-repeat center;
  bottom: -310px;
}

.token .box {
  position: relative;
  z-index: 1;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  overflow: hidden;
}

.token .blw_circle {
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.token .inbox {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.03) inset;
  padding: 10px;
  max-width: 55%;
  margin: 0 auto;
}

.token .inbox_bg {
  border-radius: 21px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: radial-gradient(70.71% 70.71% at 50% 50%,
      rgba(168, 127, 255, 0.04) 0%,
      rgba(168, 127, 255, 0) 100%),
    #000d12;
  backdrop-filter: blur(17px);
  padding: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.token .inbox h5 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}

.token .inbox_bg>div {
  position: relative;
}

.token .inbox_bg>div:not(:last-child)::before {
  content: "";
  position: absolute;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.24);
  width: 2px;
  height: 90%;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.token .inbox .center {
  width: fit-content;
  margin: 0 auto;
}

.token .white {
  color: #fff;
}

.token video {
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.wrapper {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.scanner {
  -webkit-clip-path: polygon(0 0,
      93.3673469388% 0,
      93.3673469388% 25.1275510204%,
      50% 50.1275510204%,
      0 50.1275510204%);
  clip-path: polygon(0 0,
      93.3673469388% 0,
      93.3673469388% 25.1275510204%,
      50% 50.1275510204%,
      0 50.1275510204%);
  height: 900px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-201deg);
  width: 910px;
  animation: researchRadarRotate 10s infinite linear;
  right: -2px;
  margin: auto;
}

@keyframes researchRadarRotate {
  0% {
    transform: rotate(-201deg);
  }

  100% {
    transform: rotate(159deg);
  }
}

.scanner::before {
  content: "";
  background: linear-gradient(267deg,
      rgb(7 64 90) 13.23%,
      rgba(3, 0, 20, 0) 50%);
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
}

.footer {
  background: url("./assets/images/footer_bg.png") no-repeat center;
  background-size: cover;
  padding: 35px 0;
}

ul.footer_social_links {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

ul.footer_social_links li a {
  background: #3d3f3f;
  min-width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 12px;
  color: #030704;
  text-decoration: none !important;
}

ul.footer_social_links li a:hover {
  background: #fff;
}

.footer p,
.footer p a {
  text-decoration: none;
  font-size: 14px;
  color: #898989;
  margin-bottom: 0;
  text-align: center;
}

.page_box {
  min-height: 100vh;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.page_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/right_pattern_01.png") no-repeat right top;
  top: 0;
  right: 0;
  width: 695px;
  height: 795px;
  background-size: cover;
  z-index: -1;
}

ul.dropdown-menu.walletdrop {
  background: #0b0e13;
  clip-path: polygon(10% 0%, 100% 0, 100% 90%, 90% 100%, 0 100%, 0% 10%);
}

ul.dropdown-menu.walletdrop li a {
  color: #fff;
  font-size: 14px;
}

ul.dropdown-menu.walletdrop li a:hover {
  background: #094162;
}

ul.dropdown-menu.walletdrop li a.active::after,
ul.dropdown-menu.walletdrop li a:hover::after {
  display: none;
}

.buytoken {
  padding-top: 150px;
  padding-bottom: 0px;
}

.buytoken .buytokenbg_inner {
  border-radius: 0;
  background: #0B0E13;
  /* border: 1px solid #1F252E; */
  clip-path: polygon(10% 0%, 90% 0%, 100% 10%, 100% 90%, 90% 100%, 10% 100%, 0% 90%, 0% 10%);
  padding: 50px 50px;
  position: relative;
  box-shadow: 0 0 0 100px #1F252E;
  z-index: 1;
}

.buytoken .buytokenbg {
  position: relative;
}

.buytoken .buytokenbg::before,
.buytoken .buytokenbg::after {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 60px;
  height: 55px;
  content: "";
  background-color: #1a2029;
  clip-path: polygon(0 0, 0 100%, 100% 0);
  transition: 0.3s;
}

.buytoken .buytokenbg::after {
  top: auto;
  left: auto;
  bottom: -2px;
  right: -2px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.buytoken .buytokenbg:hover::after,
.buytoken .buytokenbg:hover::before {
  background: linear-gradient(163deg, #2367e8 15.75%, #15b383 59.54%);
}

.buytoken .form-control,
.buytoken .input-group-text {
  background: transparent;
  box-shadow: none !important;
  padding: 10px;
  color: #fff;
}

.input_group {
  position: relative;
}

.buytoken .form-control {
  height: 60px;
  width: 100%;
  padding-left: 30px;
  /* background-image: url("./assets/images/form_inp_bg.svg");
  background-repeat: no-repeat;
  background-color: transparent; */
  background: #0B0E13;
  border: 1px solid #1F252E;
  color: #fff;
  font-size: 15px;
}

.buytoken .form-control::placeholder {
  color: #fff;
  font-size: 15px;
}

.buytoken .form-control:focus {
  box-shadow: none;
}

.table_sec .h5tag {
  font-size: 28px;
  color: #fff;
  display: inline-block;
}

.rdt_Table {
  color: #fff;
}

.rdt_TableCol {
  color: #fff;
  font-size: 16px;
}

.table_sec {
  padding-top: 60px;
}

.rdt_TableHeadRow {
  background: #0b0e13 !important;
  border-color: #000d12 !important;
}

.rdt_TableRow {
  background: #0b0e13 !important;
  color: #fff;
}

.rdt_TableCell {
  color: #888282;
}

.rdt_Pagination,
.rdt_Table>div,
.rdt_TableCol {
  background: #0b0e13 !important;
  color: #fff !important;
}

.rdt_Pagination select {
  outline: 0 !important;
}

.rdt_Pagination button {
  fill: #fff;
}

.rdt_Pagination button:disabled {
  fill: rgb(255 255 255 / 26%);
}

/* New Css */
.feature_box_wrapper {
  position: relative;
  transition: all 0.5s ease-in-out;
  height: 100%;
}

.feature_box {
  background: #0b0e13;
  /* border: 1px solid #1F252E; */
  min-height: 300px;
  padding: 30px;
  -webkit-clip-path: polygon(80% 0%, 100% 20%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(80% 0%, 100% 20%, 100% 100%, 0 100%, 0 0);
  position: relative;
}

/* .feature_box::after {
  content: "";
  background: #1F252E;
  width: 1px;
  height: 130px;
  position: absolute;
  top: -23px;
  right: 16px;
  transform: rotate(133deg);
} */
.feature_box_img {
  width: 88px;
  height: 88px;
  padding: 20px;
  border-radius: 100%;
  background: #11161d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.feature_box_top {
  background: #1a2029;
  width: 170px;
  height: 125px;
  -webkit-clip-path: polygon(57% 0, 100% 0, 100% 42%, 77% 20%);
  clip-path: polygon(57% 0, 100% 0, 100% 42%, 77% 20%);
  position: absolute;
  right: 0;
}

.feature_box_wrapper:hover .feature_box_top {
  background: linear-gradient(163deg, #2367e8 15.75%, #15b383 59.54%);
}

.feature_box_hidden {
  display: flex;
  transform: translateY(200%);
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.5s ease-in, opacity 0.3s ease-in;
}

.feature_box h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  transform: translateY(300%);
  transition: transform 0.5s ease-in, opacity 0.3s ease-in;
}

.feature_box_hidden p {
  color: #b3b3c0;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.feature_box_row [class*="col-"] {
  margin-bottom: 30px;
}

.feature_box_wrapper:hover .feature_box_hidden,
.feature_box_wrapper:hover .feature_box h2 {
  transform: translateY(0);
}

.para_tag {
  color: #b3b3c0;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.token_info_box h5 {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #b3b3c0;
  margin-bottom: 20px;
}

.token_info_box {
  background: #0b0e13;
  /* border: 1px solid #1F252E; */
  padding: 50px 30px;
  -webkit-clip-path: polygon(80% 0%, 100% 20%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(80% 0%, 100% 20%, 100% 100%, 0 100%, 0 0);
  position: relative;
}

.btn_grps {
  display: flex;
  gap: 30px;
}

.token-info__img {
  margin-top: 10px;
  margin-right: -14px;
  position: relative;
}

.token-info__img::before {
  position: absolute;
  top: 22px;
  right: 85px;
  width: 42px;
  height: 42px;
  background-color: #15b383;
  border-radius: 50%;
  border: 5px solid #03050e;
  content: "";
  z-index: 1;
}

.token-info--info-wrap {
  margin: 80px 0 -15px;
  position: relative;
  padding-left: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.token-info--info-wrap::before {
  position: absolute;
  top: -30px;
  right: 210px;
  width: calc(100% - 98px);
  height: 1px;
  background-color: #15b383;
  content: "";
}

.token-info__list {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  list-style: none;
  position: relative;
}

.token-info__list::before {
  position: absolute;
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: #15b383;
  content: "";
  z-index: -1;
}

.token-info__list li {
  padding: 19px 44px;
  border-radius: 25px;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.token-info__list li::after {
  content: "";
  background-image: url("../src/assets/images/token_details_bg.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.token-info__list li::before {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: url("../src/assets/images/half_circle.png") no-repeat;
  width: 14px;
  height: 7px;
  content: "";
  z-index: -1;
}

.token-info__list li h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.token-info__list li span {
  color: #b3b3c0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.slider_bottom {
  display: flex;
  gap: 30px;
}

.slider_bottom h2 {
  min-width: 20%;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.slider_bottom_content h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.slider_top_nav h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.slider_top_nav h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  transition: 0.2s all;
}

.slider_nav .slick-slide.slick-current .slider_top_nav h3 {
  opacity: 1;
}

.slider_nav .slick-list {
  background: url("../src/assets/images/roadmap_bg.svg") no-repeat center;
  height: 300px;
  /* border-bottom: 2px solid #0C122B; */
  position: relative;
}

.slider_nav .slick-list::before {
  content: "";
  background: #0c122b;
  width: 46%;
  height: 2px;
  position: absolute;
  left: 5px;
  bottom: 0;
}

.slider_nav .slick-list::after {
  content: "";
  background: #0c122b;
  width: 46%;
  height: 2px;
  position: absolute;
  right: 5px;
  bottom: 0;
}

.slider_nav .slick-slide:nth-child(odd) {
  padding-top: 120px;
}

.slider_nav .slick-slide {
  text-align: center;
  padding-top: 20px;
}

.slider_nav .slick-slide.slick-current {
  position: relative;
  z-index: 1;
}

.slider_nav .slick-slide.slick-current::before {
  content: "";
  background: linear-gradient(180deg, #2367e8 0%, rgba(3, 5, 14, 0) 100%);
  border-top: 3px solid #15b383;
  width: 200px;
  height: 280px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}

.slider_nav .slick-slide:nth-child(odd).slick-current::before {
  top: 0;
}

.slider_single {
  padding: 50px 50px 0;
}

.slider_top_nav {
  padding: 10px 40px;
}

.slider_nav .slick-prev:hover,
.slider_nav .slick-next:hover {
  background: transparent !important;
}

.slider_nav .slick-prev::before,
.slider_nav .slick-next::before {
  font-size: 0px;
  color: #36393a;
  width: 35px;
  height: 35px;
  display: block;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.slider_nav .slick-prev::before {
  background: url("./assets/images/left_arrow.png") no-repeat center;
}

.slider_nav .slick-next::before {
  background: url("./assets/images/right_arrow.png") no-repeat center;
}

.slider_nav .slick-prev,
.slider_nav .slick-next {
  bottom: -45px;
  height: 46px;
  left: 0;
  margin: 0 auto;
  right: 0;
  top: auto;
  width: 46px;
  z-index: 10;
}

.slider_nav .slick-prev {
  left: -65px;
}

.slider_nav .slick-prev:focus,
.slider_nav .slick-prev:hover {
  background: transparent !important;
}

.slider_nav .slick-next {
  background: transparent;
  right: -65px;
}

.slider_nav .slick-next:focus,
.slider_nav .slick-next:hover {
  background: transparent !important;
}
img.brand_logo {
  width: 200px;
}

/* Media Queries */
@media (max-width: 1399px) {
  .roadmap .inbox {
    padding-left: 95px;
  }

  .token-info__img::before {
    top: 20px;
    right: 64px;
  }

  .token-info--info-wrap::before {
    right: 180px;
  }
}

@media (max-width: 1199px) {
  .roadmap .box {
    width: 290px;
  }

  .platform .box {
    padding: 15px;
  }

  .platform .box .flx {
    gap: 10px;
  }

  .offcanvas {
    background: #000;
  }

  .offcanvas .links {
    flex-direction: column;
    background: transparent;
    border: 0;
  }

  .offcanvas .links li {
    margin: 10px 0;
  }

  .navbar_right {
    flex-direction: column;
  }

  .page_header::after {
    width: 50%;
  }

  .roadmap {
    background-size: 75%;
  }

  .token-info__img::before,
  .token-info--info-wrap::before,
  .token-info__list::before,
  .token-info__list li::before {
    display: none;
  }

  .token-info__img {
    text-align: center;
    margin: 20px 0;
  }

  .token-info--info-wrap {
    margin: 0;
  }

  .slider_nav .slick-list {
    background: transparent;
  }

  .slider_nav .slick-slide:nth-child(odd) {
    padding-top: 0;
  }

  .slider_nav .slick-slide {
    padding-top: 50px !important;
  }
}

@media (max-width: 991px) {
  .head {
    max-width: 100%;
  }

  .h2tag {
    font-size: 38px;
    line-height: 46px;
  }

  .page_header .img_box,
  .page_header {
    text-align: center;
  }

  .page_header::before {
    background-size: cover;
  }

  .page_header p {
    font-size: 20px;
  }

  .page_header .img_box::before {
    width: 50%;
  }

  .platform .box .flx {
    max-width: 70%;
  }

  .about .aboutbg {
    padding: 50px;
  }

  .feature .col-lg-4:not(:last-child) .box::after {
    display: none;
  }

  .faq {
    margin-top: 0;
    padding-top: 100px;
  }

  .faq .accordion {
    max-width: 100%;
  }

  .footer {
    text-align: center;
  }

  .footer p {
    margin: 20px 0;
  }

  ul.footer_social_links {
    justify-content: center;
  }

  ul.footer_social_links li a {
    margin: 0 8px;
  }

  .about .aboutbg::before {
    background: radial-gradient(98.75% 34.21% at 50% 50%,
        #3a8aac 0%,
        rgb(0 180 255 / 33%) 42.19%,
        rgb(66 52 52 / 0%) 100%);
  }

  .token .box,
  .token .outbox {
    border-radius: 0;
    width: 90%;
  }

  .token video {
    display: none;
  }

  .token .blw_circle {
    position: unset;
  }

  .token img,
  .token .outbox::before,
  .token::before,
  .token .outbox::after {
    display: none;
  }

  .token .inbox {
    max-width: 100%;
  }

  .token .inbox_bg {
    display: block;
    text-align: center;
  }

  .token .inbox_bg>div {
    margin: 0 0 10px !important;
    padding: 0 !important;
  }

  .token .inbox_bg>div:nth-last-child(1) {
    margin: 0 !important;
  }

  .wrapper,
  .token .inbox_bg>div:not(:last-child)::before {
    display: none;
  }

  .token-info--info-wrap {
    flex-wrap: wrap;
  }

  .token-info__list {
    width: 100%;
  }

  .token-info__list li {
    text-align: center;
  }
}

@media (max-width: 767px) {

  .h2tag,
  .feature .box:hover h5,
  .page_header h1 {
    color: #fff;
  }

  .roadmap .box {
    width: unset;
    border: 0 !important;
    padding: 0 !important;
  }

  .roadmap .box::after,
  .roadmap .box::before {
    display: none;
  }

  .roadmap .inbox {
    padding-left: 125px;
  }

  .roadmap .inbox::after {
    background-position: left;
  }

  .roadmap .slick-list {
    height: 230px;
  }

  .roadmap .slick-list::before {
    display: none;
  }

  .roadmap .slick-slide:nth-child(even) {
    transform: translateY(0px);
  }

  .roadmap .slick-prev,
  .roadmap .slick-next {
    bottom: -95px;
  }

  .buytoken .buytokenbg {
    padding: 30px 20px;
  }

  .faq .accordion-item button {
    font-size: 17px;
  }

  .roadmap ul {
    font-size: 16px;
  }

  .feature,
  .token {
    padding: 35px 0;
  }

  .feature .box {
    text-align: center;
  }

  .feature .img_box {
    margin: 0 auto 10px;
  }

  .feature .img_box::before {
    right: 0;
    margin: auto;
  }

  .about .aboutbg {
    text-align: center;
  }

  .roadmap {
    padding: 95px 0 0;
  }

  .btn_grps {
    flex-wrap: wrap;
  }

  .slider_bottom {
    flex-direction: column;
    row-gap: 15px;
  }

  .slider_single {
    padding: 50px 15px 0;
  }

  .about ul li {
    width: 100%;
  }

  .buytoken .buytokenbg::before,
  .buytoken .buytokenbg::after {
    display: none;
  }
}

@media (max-width: 575px) {
  .h2tag {
    font-size: 32px;
  }

  .page_header h1 {
    font-size: 36px;
  }

  .page_header .img_box::before {
    height: 50%;
  }

  .platform .box {
    border-radius: 30px;
  }

  .platform .box::before {
    top: -70px;
    width: 70%;
    height: 70%;
  }

  .platform .inbox {
    padding: 15px 10px;
  }

  .platform .box .flx {
    max-width: 95%;
    display: block;
  }

  .platform .inbox {
    margin-top: 15px;
  }

  .about .aboutbg {
    padding: 25px;
    border-radius: 30px;
  }

  .token-info__list li::after {
    display: none;
  }

  .token-info__list li {
    background: #0b0e13;
    border: 1px solid #1f252e;
  }

  .buytoken .buytokenbg_inner {
    padding: 50px 15px;
  }
}